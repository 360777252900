<template>
  <div class="order_page" style="width: 100%">
    <div style="
        box-shadow: 10px 10px 10px rgb(0 0 0 / 35%);
        background: #fff;
        padding: 30px;
        height: 100%;
        box-sizing: border-box;
      ">
      <div class="page_title">{{ language.Daily_Statement }}</div>
      <div style="margin-top: 30px">
        <div style="display: flex">
          <!-- 选择月 -->
          <div class="input_box">
            <el-date-picker v-model="Select_Month" type="month" :placeholder="language.Select_Month">
            </el-date-picker>
          </div>
          <!-- 选择币种 -->
          <div class="input_box input_box3">
            <el-select v-model="Currency" :placeholder="language.Currency">
              <el-option v-for="item in options2" :key="item.type_name" :label="item.type_name" :value="item">
              </el-option>
            </el-select>
          </div>
          <!-- 查询 -->
          <div class="input_box">
            <el-button type="primary" id="SearchBtn" @click="SearchBtn()">{{
              language.Search
            }}</el-button>
          </div>
          <!-- 重置 -->
          <div class="input_box">
            <el-button type="danger" id="ResetBtn" @click="ResetBtn()">{{
              language.Reset
            }}</el-button>
          </div>
          <!-- 打印 -->
          <div class="input_box Export_box" :style="Exporttype == 2 ? 'min-width:200px' : ''">
            <el-button v-if="Exporttype == 1" type="success" @click="ExportBnt()">{{
              language.Export
            }}</el-button>
            <el-progress v-if="Exporttype == 2" style="width: 100%" :percentage="percentage"
              :show-text="false"></el-progress>
            <download-excel v-if="Exporttype == 3" class="export-excel-wrapper" :data="json_table" :fields="json_fields"
              type="txt" :name="ordername + '.xls'">
              <el-button type="success">{{ language.Download_Bill }}</el-button>
            </download-excel>
          </div>
        </div>
      </div>
      <div style="margin-top: 10px">
        <div style="display: flex; align-items: center; justify-content: space-between">
          <div style="padding: 10px 10px 10px 0">
            <div style="
                width: 26px;
                height: 26px;
                display: flex;
                align-items: center;
                justify-content: center;
                background: #2c3e50;
                cursor: pointer;
              " @click="init()">
              <img style="width: 16px" src="../../static/Refresh.png" alt="" />
            </div>
          </div>
          <div style="display: flex; justify-content: flex-end">
            <div style="display: flex; margin-right: 50px">
              <div>{{ language.TotalNumberOfTransactions }}:</div>
              <div>{{ totalNumber }}</div>
            </div>
            <div style="display: flex">
              <div>{{ language.TotalAmount }}:</div>
              <div>{{ totalMoney }}{{ Currency.type_name }}</div>
            </div>
          </div>
        </div>
        <el-table :data="tableData" border style="flex: 1" stripe v-loading="loading"
          :header-cell-style="{ background: '#fafafa' }">
          <el-table-column prop="date" :label="language.Statement_Date" min-width="10%">
          </el-table-column>
          <el-table-column prop="number" :label="language.Number_of_transactions" min-width="10%">
          </el-table-column>
          <el-table-column prop="money" min-width="10%" :label="Currency.type_name + language.Transaction_Amount">
          </el-table-column>
          <el-table-column prop="service_fee" min-width="10%" :label="Currency.type_name + language.ServiceCharge">
          </el-table-column>
          <el-table-column prop="settle_amount" min-width="10%"
            :label="Currency.type_name + language.Settlement_Amount">
          </el-table-column>
          <el-table-column v-if="rules.mchorderindex" fixed="right" :label="language.Operation" min-width="10%">
            <template slot-scope="scope">
              <el-button @click="handleClick(scope.row)" type="text" size="small">{{
                language.Download_Bill
              }}</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div>
        <div class="paging">
          <el-pagination v-loading="loading" @size-change="handleSizeChange" @current-change="handleCurrentChange"
            current-page.sync="5" :page-sizes="[10, 15, 20, 25, 30]" :page-size="limit"
            layout="sizes, prev, pager, next" :total="pages">
          </el-pagination>
        </div>
      </div>
    </div>
    <div style="display: none">
      <download-excel id="export-excel-wrapper" class="export-excel-wrapper" :data="json_table"
        v-if="exportexcelwrapper2" :fields="json_fields" type="txt" :name="ordername + '.xls'">
        <el-button type="success">{{ language.Download_Bill }}</el-button>
      </download-excel>
    </div>
  </div>
</template>

<script>
import rules from "../../common/js/rules";
import payment from "../../common/json/payment.json";
import { orderdayBill, order } from "../../api/index.js";
export default {
  data() {
    return {
      loading: false, //加载
      totalNumber: "0", //总笔数
      totalMoney: "0", //总笔数
      language: "",
      tableData: [],
      json_fields: {
        // "名称": "name",//常规字段
        // "Telephone": "phone.mobile", //支持嵌套属性
      },
      json_table: [],
      json_meta: [
        [
          {
            " key ": " charset ",
            " value ": " utf- 8 ",
          },
        ],
      ],
      Select_Month: "", //选择月
      showlimit: 10,
      pages: null,
      page: 1,
      limit: 15,
      searchtime: "", //格式时间
      Exporttype: 1,
      Exportpage: 0, //导出页数
      Exportpageing: 0, //已导出的页数
      Exporttrue: false,
      percentage: 0, //到处百分百
      exportexcelwrapper2: false,
      ordername: "",
      options2: [],
      Currency: {
        value: "MYR",
        type_name: "MYR",
      }, //币种筛选
      userinfo: "",
      Paymentchannellist2: [],
      rules: "",
    };
  },
  created() {
    this.options2 = [...this.options2, ...payment.otherCurrencylist];
    this.changelanguage();
    this.init();
    this.userinfo = JSON.parse(localStorage.getItem("userinfo"));
    if (this.userinfo.currency != "all") {
      for (let i = this.options2.length - 1; i >= 0; i--) {
        if (this.options2[i].type_name != this.userinfo.currency) {
          this.options2.splice(i, 1);
        } else {
          this.Currency = this.options2[i];
        }
      }
    }
    this.Currency.value = this.options2[0].type_name;
    this.Currency.type_name = this.options2[0].type_name;
  },
  mounted() {
    this.rules = rules.rules().msg;
  },
  watch: {
    "$store.state.language"() {
      this.changelanguage();
    },
    Exporttrue() {
      if (this.Exporttrue) {
        if (this.Exportpageing < this.Exportpage) {
          this.Exportpageing = this.Exportpageing + 1;
          this.Exporttrue = false;
          this.forExport();
        }
      }
    },
    Currency() {
      this.page = 1;
      this.init();
    },
  },

  methods: {
    // 获取列表
    init() {
      this.tableData = [];
      this.loading = true;
      let data = {
        page: this.page,
        limit: this.limit,
      };
      if (this.Currency.value) {
        data.currency = this.Currency.value;
      }
      if (this.Select_Month) {
        data.datetime = this.searchtime;
      }
      orderdayBill({}, data).then((res) => {
        if (res.code == 1) {
          this.loading = false;
          this.tableData = res.data.list;
          this.pages = res.data.count;
          this.totalNumber = res.data.totalNumber;
          this.totalMoney = res.data.totalMoney;
        }
      });
    },
    // 查询
    SearchBtn() {
      document.getElementById("SearchBtn").blur();
      // let time =new Date()
      if (this.Select_Month) {
        this.searchtime =
          this.$getNowFormatDate.getNowFormatDate(this.Select_Month) +
          " 00:00:00 - " +
          this.$getNowFormatDate.getNowFormatDate2(this.Select_Month) +
          " 23:59:59";
      }
      this.page = 1;
      this.pages = 1;
      this.Exporttype = 1;
      this.init();
    },
    // 重置
    ResetBtn() {
      document.getElementById("ResetBtn").blur();
      this.Select_Month = "";
      this.Currency = this.options2[0];
      this.page = 1;
      this.pages = 1;
      this.init();
    },
    // 切页
    handleCurrentChange(val) {
      this.page = val;
      this.init();
    },
    handleSizeChange(val) {
      this.page = 1;
      this.limit = val;
      this.init();
    },
    changelanguage() {
      this.language = this.$units();
      let msg = [
        {
          label: this.language.Incompleteprocess,
          value: "-1",
        },
      ];
      this.Paymentchannellist2 = [...msg, ...payment.Paymentmethod];
      this.json_fields = {
        [this.language.Merchantordernumber]: {
          field: "mch_order_no",
          callback: (value) => {
            return "&nbsp;" + value;
          },
        },
        ["YDF-pay " + this.language.orderNumber]: {
          field: "my_order_no",
          callback: (value) => {
            return "&nbsp;" + value;
          },
        },
        [this.language.TradeName]: {
          field: "order_goods_name",
          callback: (value) => {
            return "&nbsp;" + value;
          },
        },
        [this.language.Apply_No + "/appId"]: {
          field: "appid",
          callback: (value) => {
            return "&nbsp;" + value;
          },
        },
        [this.language.TransactionChannel]: "pay_from_id",
        [this.language.Amount]: "money",
        [this.language.Actualpaymentamount]: "pay_amount",
        [this.language.ServiceCharge]: "service_fee",
        [this.language.Merchantsettlementamount]: "settle_amount",
        [this.language.TransactionTime]: "create_time",
        [this.language.PaymentTime]: "pay_server_time",
        [this.language.Source]: "pay_scene",
        [this.language.State]: "pay_state",
      };
    },
    handleClick(e) {
      this.$message({
        message: this.language.Exportingpleasedonotjumptothepageorotheroperations,
        type: "warning",
      });
      this.searchtime = e.date + " 00:00:00 - " + e.date + " 23:59:59";
      this.Exportpageing = 1;
      this.Exporttype = 1;
      this.json_table = [];
      this.forExport();
    },
    // 导出
    ExportBnt() {
      if (this.Select_Month) {
        this.searchtime =
          this.$getNowFormatDate.getNowFormatDate(this.Select_Month) +
          " 00:00:00 - " +
          this.$getNowFormatDate.getNowFormatDate2(this.Select_Month) +
          " 23:59:59";
      } else {
        this.$message({
          message: this.language.Pleaseselectatime,
          type: "warning",
        });
        return;
      }
      this.$message({
        message: this.language.Exportingpleasedonotjumptothepageorotheroperations,
        type: "warning",
      });
      this.Exportpageing = 1;
      this.Exporttype = 2;
      this.json_table = [];
      this.forExport();
    },
    // 循环到处
    forExport() {
      let data;
      data = {
        page: this.Exportpageing,
        limit: 100,
      };
      if (this.Currency.value) {
        data.currency = this.Currency.value;
      }
      this.Exporttrue = false;
      if (this.searchtime) {
        data.datetime = this.searchtime;
      }
      data.isDch = 0;
      order({}, data).then((res) => {
        if (res.code == 1) {
          if (res.data.count == 0) {
            this.Exporttype = 1;
            this.$message({
              message: this.language.Nodata,
              type: "warning",
            });
            return;
          }
          this.Exportpage = Math.ceil(res.data.count / 100);
          this.Exporttrue = true;
          this.percentage = (this.Exportpageing / this.Exportpage) * 100;
          for (let i = 0; i < res.data.list.length; i++) {
            if (res.data.list[i].pay_scene == 0) {
              res.data.list[i].pay_scene = this.language.Mobilewebpage;
            } else if (res.data.list[i].pay_scene == 1) {
              res.data.list[i].pay_scene = this.language.officialaccount;
            } else if (res.data.list[i].pay_scene == 2) {
              res.data.list[i].pay_scene = this.language.Applets;
            } else if (res.data.list[i].pay_scene == 3) {
              res.data.list[i].pay_scene = this.language.Computerwebsite;
            }

            if (res.data.list[i].pay_state == 0) {
              res.data.list[i].pay_state = this.language.Unpaid;
            } else if (res.data.list[i].pay_state == 1) {
              res.data.list[i].pay_state = this.language.Paidbutnotreceived;
            } else if (res.data.list[i].pay_state == 2) {
              res.data.list[i].pay_state = this.language.Paidandreceived;
            } else if (res.data.list[i].pay_state == 3) {
              res.data.list[i].pay_state = this.language.Canceled;
            }

            for (let j = 0; j < this.Paymentchannellist2.length; j++) {
              if (res.data.list[i].pay_from_id == this.Paymentchannellist2[j].value) {
                res.data.list[i].pay_from_id = this.Paymentchannellist2[j].label;
              }
            }
            // if (res.data.list[i].pay_from_id == 0) {
            //   res.data.list[i].pay_from_id = this.language.Alipay;
            // } else if (res.data.list[i].pay_from_id == 1) {
            //   res.data.list[i].pay_from_id = this.language.WeChat;
            // } else if (res.data.list[i].pay_from_id == -1) {
            //   res.data.list[i].pay_from_id = "";
            // }
            res.data.list[i].money =
              res.data.list[i].money + " " + res.data.list[i].currency;
            res.data.list[i].pay_amount =
              res.data.list[i].pay_amount + " " + res.data.list[i].currency;
            res.data.list[i].service_fee =
              res.data.list[i].service_fee + " " + res.data.list[i].currency;
            if (res.data.list[i].pay_server_time) {
              res.data.list[i].pay_server_time = this.$getNowFormatDate.chagetime(
                res.data.list[i].pay_server_time
              );
            }
          }

          //
          this.json_table = [...this.json_table, ...res.data.list];
          if (this.Exporttype == 2) {
            this.ordername = "Order" + this.$getNowFormatDate.getNowtime(new Date());
            if (this.Exportpage == this.Exportpageing) {
              this.$nextTick(() => {
                this.Exporttype = 3;
              });
            }
          } else {
            this.ordername = "Order" + this.$getNowFormatDate.getNowtime(new Date());
            this.exportexcelwrapper2 = false;
            this.$nextTick(() => {
              this.exportexcelwrapper2 = true;
              setTimeout(() => {
                document.getElementById("export-excel-wrapper").click();
              }, 0);
            });
          }
        }
      });
    },
  },
};
</script>
<style scoped>
* {
  box-sizing: border-box;
}

.order_page {
  min-height: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.order_page>div {
  min-height: calc(100vh - 129px);
}

.page_title {
  font-size: 26px;
  font-weight: 600;
}

.input_box {
  margin-right: 30px;
}

.paging {
  width: 100%;
  display: flex;
  margin-top: 30px;
  justify-content: flex-end;
}

* /deep/ .el-table .cell {
  white-space: nowrap;
}
</style>
